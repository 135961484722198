@import-normalize;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  cursor: pointer;
}

@font-face {
  font-family: 'Mark Pro';
  src: local('Mark Pro'), url(./assets/fonts/MARKPROMEDIUM.OTF) format('openType');
}

@import '~react-image-gallery/styles/css/image-gallery.css';

.color-scroll::-webkit-scrollbar {
  width: 8px;
  height : 8px;
  background : #F2F2F2;
  border-radius: 10px;
}

/* Track */
.color-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}
 
/* Handle */
.color-scroll::-webkit-scrollbar-thumb {
  background: rgba(75,65,196,1);
  border-radius: 10px;
  width : 8px;
  height : 8px;
}

/* Handle on hover */
.color-scroll::-webkit-scrollbar-thumb:hover {
  background: #8F8F8F; 
}

.fullscreen {
  margin: 0 auto;
  width: 65%; 
  transition: all 1s ease;
}

input[type="checkbox"] {
accent-color: rgba(75,65,196,1) !important;
}

.Toastify__progress-bar--success {
  color: #4b41c4 !important;
  background-color: #4b41c4 !important;
}

.Toastify {
  --toastify-icon-color-success: #4b41c4 !important;
}

.noArrowsNumberInput {
    /* hide arrows, all browser except firefox */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type='number'] {
      appearance: none;
      -moz-appearance: textfield;
    }
}


.ant-select-clear {
  margin-top: -15px !important;
  width: 30px !important;
  height: 30px  !important;
}

.ant-select-tree-switcher {
    display: flex !important;
    justify-content: center !important;
    margin: auto 0 !important;
}

.ant-drawer-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}